<template lang="pug">
    .main-wrapper.estabelecimento-salvar

        HorarioDialog(:visible='horario_modal' :model='model'  @close="onCloseHorarioDialog()")

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "estabelecimentos" }'> Estabelecimentos</router-link> /
                            <b>{{ model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm || waiting' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } estabelecimentos`")
                        .p-grid.nested-grid.p-fluid

                            .p-col-12.p-md-9
                                .p-grid.p-align-end

                                    .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.nm_razao_social.$error }")
                                        label.form-label Razão social:
                                        InputText(type='text' v-model='$v.model.nm_razao_social.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nm_razao_social.$error')
                                            .form-message--error(v-if="!$v.model.nm_razao_social.minLength") <b>Razão Social</b> deve ter pelo menos 2 caracteres.
                                            .form-message--error(v-if="!$v.model.nm_razao_social.required") <b>Razão Social</b> é obrigatório.

                                    .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.nr_cnpj.$error }")
                                        label.form-label CNPJ:
                                        InputMask(mask='99.999.999/9999-99' v-model='$v.model.nr_cnpj.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nr_cnpj.$error')
                                            .form-message--error(v-if="!$v.model.nr_cnpj.minLength") <b>CNPJ</b> deve ter pelo menos 2 caracteres.
                                            .form-message--error(v-if="!$v.model.nr_cnpj.required") <b>CNPJ</b> é obrigatório.

                                    .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.nm_fantasia.$error }")
                                        label.form-label Nome fantasia:
                                        InputText(type='text' v-model='$v.model.nm_fantasia.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nm_fantasia.$error')
                                            .form-message--error(v-if="!$v.model.nm_fantasia.minLength") <b>Nome Fantasia</b> deve ter pelo menos 2 caracteres.
                                            .form-message--error(v-if="!$v.model.nm_fantasia.required") <b>Nome Fantasia</b> é obrigatório.

                                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_responsavel_tecnico.$error }")
                                        label.form-label Responsável técnico:
                                        InputText(type='text' v-model='$v.model.nm_responsavel_tecnico.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nm_responsavel_tecnico.$error')
                                            .form-message--error(v-if="!$v.model.nm_responsavel_tecnico.minLength") <b>Responsável técnico</b> deve ter pelo menos 2 caracteres.
                                            .form-message--error(v-if="!$v.model.nm_responsavel_tecnico.required") <b>Responsável técnico</b> é obrigatório.

                                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_responsavel_tecnico.$error }")
                                       label.form-label Conselho (sigla):
                                        Dropdown(v-model='$v.model.nm_conselho_responsavel_tecnico.$model' :options='options.nm_conselho_responsavel_tecnico'
                                            optionLabel='value' optionValue='value' placeholder='Selecione')
                                        .feedback--errors(v-if='submitted && $v.model.nm_conselho_responsavel_tecnico.$error')
                                            .form-message--error(v-if="!$v.model.nm_conselho_responsavel_tecnico.required") <b>Nome do conselho</b> é obrigatório.

                                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_responsavel_tecnico.$error }")
                                        label.form-label UF Conselho:
                                        Dropdown(v-model='$v.model.cd_uf_conselho_responsavel_tecnico.$model' :options='options.ufs'
                                            optionLabel='text' optionValue='value' placeholder='Selecione')
                                        .feedback--errors(v-if='submitted && $v.model.cd_uf_conselho_responsavel_tecnico.$error')
                                            .form-message--error(v-if="!$v.model.cd_uf_conselho_responsavel_tecnico.required") <b>UF do conselho</b> é obrigatório.

                                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_responsavel_tecnico.$error }")
                                        label.form-label Nº do conselho:
                                        InputText(type='text' v-model='$v.model.nr_conselho_responsavel_tecnico.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nr_conselho_responsavel_tecnico.$error')
                                            .form-message--error(v-if="!$v.model.nr_conselho_responsavel_tecnico.required") <b>Número do conselho</b> é obrigatório.

                                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_bairro.$error }")
                                        label.form-label Bairro:
                                        InputText(type='text' v-model='$v.model.nm_bairro.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nm_bairro.$error')
                                            .form-message--error(v-if="!$v.model.nm_bairro.minLength") <b>Bairro</b> deve ter pelo menos 2 caracteres.
                                            .form-message--error(v-if="!$v.model.nm_bairro.required") <b>Bairro</b> é obrigatório.

                                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_rua.$error }")
                                        label.form-label Rua:
                                        InputText(type='text' v-model='$v.model.nm_rua.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nm_rua.$error')
                                            .form-message--error(v-if="!$v.model.nm_rua.minLength") <b>Rua</b> deve ter pelo menos 2 caracteres.
                                            .form-message--error(v-if="!$v.model.nm_rua.required") <b>Rua</b> é obrigatório.

                                    .p-col-12.p-md-6
                                        label.form-label Complemento:
                                        InputText(type='text' v-model='model.ds_complemento')

                                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                        label.form-label CEP:
                                        InputMask(mask='99999-999' v-model='$v.model.nr_cep.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                                            .form-message--error(v-if="!$v.model.nr_cep.minLength") <b>CEP</b> deve ter pelo menos 2 caracteres.
                                            .form-message--error(v-if="!$v.model.nr_cep.required") <b>CEP</b> é obrigatório.

                                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_estabelecimento.$error }")
                                        label.form-label Número do estabelecimento:
                                        InputText(type='text' v-model='$v.model.nr_estabelecimento.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nr_estabelecimento.$error')
                                            .form-message--error(v-if="!$v.model.nr_estabelecimento.minLength") <b>Número do Estabelecimento</b> deve ter pelo menos 2 caracteres.
                                            .form-message--error(v-if="!$v.model.nr_estabelecimento.required") <b>Número do Estabelecimento</b> é obrigatório.

                                    .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.cd_estado.$error }")
                                        label.form-label Estado *:
                                        Dropdown(v-model='$v.model.cd_estado.$model' :options='options.ufs' @change='getCidades()'
                                            optionLabel='textAlt' optionValue='value' placeholder='Selecione' filter)
                                        .feedback--errors(v-if='submitted && $v.model.cd_estado.$error')
                                        .form-message--error(v-if="!$v.model.cd_estado.required") <b>Estado</b> é obrigatório.

                                    .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.cd_cidade.$error }")
                                        label.form-label Município *:
                                        Dropdown(v-model='$v.model.cd_cidade.$model' :options='options.cidades'
                                            optionLabel='text' optionValue='value' placeholder='Selecione' filter)
                                        .feedback--errors(v-if='submitted && $v.model.cd_cidade.$error')
                                        .form-message--error(v-if="!$v.model.cd_cidade.required") <b>Município</b> é obrigatório.

                                    .p-col-12.p-md-2(style='width:150px; height:50px;')
                                        .p-field-checkbox
                                            label.form-label(style="margin:0") Listar cidade no aplicativo?
                                            Checkbox(v-model="$v.model.ie_cidade_app.$model" :binary="true" style="margin-left:10px")

                                    .p-col-12.p-md-3
                                        label.form-label Latitude:
                                        InputText(type='text' v-model='model.nr_latitude')

                                    .p-col-12.p-md-3
                                        label.form-label Longitude:
                                        InputText(type='text' v-model='model.nr_longitude')

                                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_telefone.$error }")
                                        label.form-label Telefone:
                                        InputMask(:mask="'(99) 99999999?9'" v-model='$v.model.nr_telefone.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nr_telefone.$error')
                                            .form-message--error(v-if="!$v.model.nr_telefone.minLength") <b>Telefone</b> deve ter pelo menos 2 caracteres.
                                            .form-message--error(v-if="!$v.model.nr_telefone.required") <b>Telefone</b> é obrigatório.

                                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_whatsapp_cliente.$error }")
                                        label.form-label WhatsApp Clínica:
                                        InputMask(:mask="'(99) 99999999?9'" v-model='$v.model.nr_whatsapp_cliente.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nr_whatsapp_cliente.$error')
                                            .form-message--error(v-if="!$v.model.nr_whatsapp_cliente.minLength") <b>WhatsApp Clínica</b> deve ter pelo menos 2 caracteres.

                                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_whatsapp.$error }")
                                        label.form-label WhatsApp Coordenação:
                                        InputMask(:mask="'(99) 99999999?9'" v-model='$v.model.nr_whatsapp.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nr_whatsapp.$error')
                                            .form-message--error(v-if="!$v.model.nr_whatsapp.minLength") <b>WhatsApp Coordenação</b> deve ter pelo menos 2 caracteres.

                                    .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.ds_email.$error }")
                                        label.form-label Email:
                                        InputText(type='text' v-model='$v.model.ds_email.$model')
                                        .feedback--errors(v-if='submitted && $v.model.ds_email.$error')
                                            .form-message--error(v-if="!$v.model.ds_email.minLength") <b>Email</b> deve ter pelo menos 2 caracteres.
                                            .form-message--error(v-if="!$v.model.ds_email.required") <b>Email</b> é obrigatório.

                                    .p-col-12.p-md-2(style='width:148px; height:50px;margin-right:34px;')
                                        .p-field-checkbox
                                            label.form-label(style="margin:0") Exibir na rede credenciada?
                                            Checkbox(v-model="$v.model.ie_rede_credenciada.$model" :binary="true")
                                    .p-col-12.p-md-1(style='width:90px; height:50px;margin-right:40px;')
                                        .p-field-checkbox
                                            label.form-label(style="margin:0") Está ativo?
                                            Checkbox(
                                                v-model="$v.model.ie_status.$model"
                                                :binary="true"
                                                style="margin-left:10px"
                                            )

                                    .p-col-12.p-md-2(style='width:160px; height:50px;')
                                        .p-field-checkbox
                                            label.form-label(style="margin:0") Permite validar guias?
                                            Checkbox(
                                                v-model="$v.model.ie_valida_guia.$model"
                                                :binary="true"
                                                style="margin-left:10px"
                                            )

                                    .p-col-12.p-md-2
                                        label.form-label Ordem de listagem:
                                        InputText(type='number' v-model='model.nr_ordem_listagem')

                                    .p-col-12.p-md-4(style='width:340px; height:43px;')
                                        .p-field-checkbox
                                            label.form-label(style="margin:0") Notificar agendamentos por Whatsapp?
                                            Checkbox(v-model="$v.model.ie_notificacao_agendamentos.$model" :binary="true" style="margin-left:10px")

                                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.ie_tipo_notificacao_agendamentos.$error }")
                                        label.form-label Tipo de notificação:
                                        Dropdown(v-model='$v.model.ie_tipo_notificacao_agendamentos.$model' :options='options.tipo_notificacao_whatsapp' dataKey='id'
                                        :filter='options.tipo_notificacao_whatsapp.length > 0' optionLabel='text' optionValue='value' placeholder='Selecione...')
                                        .feedback--errors(v-if='submitted && $v.model.ie_tipo_notificacao_agendamentos.$error')
                                            .form-message--error(v-if="!$v.model.ie_tipo_notificacao_agendamentos.required") <b>Tipo de notificação</b> é obrigatório.
                                    .p-col-12.p-md-3
                                        label.form-label Grupo de Estabelecimento:
                                        Dropdown(v-model='model.ie_grupo' :options='options.grupos' dataKey='value' :showClear="true"
                                        :filter='options.grupos.length > 0' optionLabel='label' optionValue='value' placeholder='Selecione...')
                                    .p-col-12.p-md-3
                                        label.form-label Justificativa:
                                        Textarea(
                                            type='text'
                                            v-model='model.justificativa'
                                            :autoResize="false"
                                            rows="3"
                                            :disabled="$v.model.ie_status.$model"
                                        )
                                    .p-col-12.p-md-3
                                        label.form-label Código de Integração Shift:
                                        InputNumber(:useGrouping="false" v-model='model.cd_integracao_shift' 
                                        :autoClear='false' 
                                        :slotChar="''" 
                                        placeholder='Código de Integração Shift')
                                    .p-col-12
                                        label.form-label Observações:
                                        Textarea(type='text' v-model='model.ds_observacao' :autoResize="false" rows="5")

                            .p-col-12.p-md-3
                                .p-grid.p-fluid.p-justify-center

                                    .p-col-10
                                        label.form-label Foto:
                                        .image-wrapper
                                            img(:src='model.path_foto')

                                    .p-col-10
                                        FileUpload(
                                            name='aq_foto'
                                            chooseLabel='Escolher'
                                            mode='basic'
                                            accept="image/*"
                                            :auto='true'
                                            @before-send='beforeSendImage'
                                            )

                            .p-col-12.p-md-9
                                .p-grid.p-fluid.p-align-start.p-justify-start
                                    .p-col-12
                                        h4.text-box Dados Bancario
                                    .p-col-12.p-grid.p-fluid.p-align-start.p-justify-start(v-for="(n_input, index) in model.estabelecimento_banco" :key="index")
                                        .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.cd_estado.$error }")
                                            label.form-label Banco:
                                            Dropdown(v-model='n_input.cd_banco'
                                                :id='"banco"+n_input.cd_banco'
                                                :options='options.bancos'
                                                optionLabel='nm_banco'
                                                optionValue='id'
                                                placeholder='Selecione'
                                                :filter='options.bancos.length > 0')
                                            .feedback--errors(v-if='submitted && $v.model.cd_estado.$error')
                                                .form-message--error(v-if="!$v.model.cd_estado.required") <b>Estado</b> é obrigatório.

                                        .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                            label.form-label Número Conta:
                                            InputText(:id='"conta"+n_input.id' :useGrouping="false" v-model='n_input.nr_conta' :autoClear='false' :slotChar="''" placeholder='Número Conta')
                                            .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                                                .form-message--error(v-if="!$v.model.nr_cep.minLength") <b>CEP</b> inválido.

                                        .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                            label.form-label Número Agencia:
                                            InputText(:id='"agencia"+n_input.id' :useGrouping="false" v-model='n_input.nr_agencia_bancaria' :autoClear='false' :slotChar="''" placeholder='Número Agencia')
                                            .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                                                .form-message--err  or(v-if="!$v.model.nr_cep.minLength") <b>CEP</b> inválido.

                                        .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                            label.form-label Operação:
                                            InputText(:id='"operacao"+n_input.id' :useGrouping="false" v-model='n_input.nr_operacao' :autoClear='false' :slotChar="''" placeholder='Operacao')
                                            .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                                                .form-message--error(v-if="!$v.model.nr_cep.minLength") <b>CEP</b> inválido.

                                        .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                            label.form-label Chave PIX:
                                            InputText(type='text' v-model='n_input.ds_chave_pix' placeholder='(Opcional)')
                                            .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                                                .form-message--error(v-if="!$v.model.nr_cep.minLength") <b>CEP</b> inválido.
                                        .p-col-12.p-md-2.btn-remove-item
                                            Button(
                                                type="button",
                                                icon="jam jam-trash",
                                                class="p-button-danger"
                                                style="max-width: 160px; margin-right:5px",
                                                @click="removeBanco(index)"
                                                v-if="model.estabelecimento_banco.length > 0"
                                            )
                                            Button(
                                                type="button",
                                                icon="jam jam-plus",
                                                style="max-width: 160px",
                                                @click="addBanco(n_input)"
                                                v-if="model.estabelecimento_banco.length === (index+1)"
                                            )

                            //- .p-col-12(v-if='! model.id')
                            //-     PickList.picklist-procedimentos(v-model='options.procedimentos')
                            //-         template(#sourceHeader)
                            //-             span Procedimentos:
                            //-             .p-inputgroup.mt-1
                            //-                 InputText(v-model='filters.procedimentos')
                            //-                 Button(icon='jam jam-search')
                            //-         template(#targetHeader)
                            //-             div(style='margin-top:19px;margin-bottom:19px') Selecionados:
                            //-         template(#item='props')
                            //-             div(v-show='filterResult(props.item)' style='padding:0.429em 0.857em')
                            //-                 span <b>{{ props.item.textAlt }}</b>
                            //-                 //span <b>{{ props.item.textAlt }}</b><br>
                            //-                 //span {{ props.item.nm_procedimento }}
                            
                                b.ta-center Carregando Especialidades e Grupo de Procedimentos
                            Panel.p-col-12.my-1(header='Especialidades e Grupos de Procedimentos'  mode="indeterminate")
                                ProgressBar.mt-1(v-if='waitingGruposProcedimentos && waitingEspecialidades' mode="indeterminate")
                                .p-col-12(v-else)
                                    .p-col-12.ta-center(v-if='waitingEspecialidades')
                                        ProgressSpinner(strokeWidth=2)
                                    .p-col-12(v-else)
                                        PickList(v-model='options.especialidades' @move-to-target='onMoveToTarget' @move-to-source='onMoveToSource')
                                            template(#sourceHeader) Especialidades:
                                            template(#targetHeader) Selecionadas:
                                            template(#item='props')
                                                span {{ props.item.nm_especialidade }}

                                    .p-col-12(v-if='waitingGruposProcedimentos')
                                        ProgressBar.mt-1(mode="indeterminate")
                                    .p-col-12(v-else)
                                        PickList(v-model='options.grupo_procedimentos' @move-to-target='onMoveToTargetGruposProcedimentos' @move-to-source='onMoveToSourceGruposProcedimentos')
                                            template(#sourceHeader) Grupos de Procedimentos:
                                            template(#targetHeader) Selecionados:
                                            template(#item='props')
                                                span {{ props.item.ds_descricao }}

                            .p-col-12.integracao
                                .p-grid.p-fluid.p-align-start
                                    .p-col-12.p-md-3
                                        label.form-label Tipo:
                                        Dropdown(v-model='$v.model.ds_integracao_agendamento_tipo.$model' :options='options.tipo_integracao' dataKey='id' :filter='options.tipo_integracao.length > 0'
                                        optionLabel='text' optionValue='value' placeholder='Selecione...')
                                    .p-col-12.p-md-3
                                        label.form-label URL:
                                        InputText(type='text' v-model='$v.model.ds_integracao_agendamento_url.$model')
                                    .p-col-12.p-md-3
                                        label.form-label TOKEN:
                                        InputText(type='text' v-model='$v.model.ds_integracao_agendamento_token.$model')
                                    .p-col-12.p-md-3
                                        label.form-label Client ID:
                                        InputText(type='text' v-model='$v.model.ds_integracao_agendamento_cliente_id.$model')
                                    .p-col-12.p-md-3
                                        label.form-label Client Secret:
                                        InputText(type='text' v-model='$v.model.ds_integracao_agendamento_client_secret.$model')

                            .p-col-12.integracao(style="margin-top:20px")
                                .p-grid.p-fluid.p-align-start
                                    .p-col-12
                                        h3 Configurações de Cirurgias
                                    .p-col-12.p-md-4
                                        label.form-label Pagamento Médico:
                                        InputText(type='text' v-model='$v.model.orcamento_pagamento_medico.$model')
                                    .p-col-12.p-md-4
                                        label.form-label Pagamento Hospital:
                                        InputText(type='text' v-model='$v.model.orcamento_pagamento_hospital.$model')
                                    .p-col-12.p-md-4
                                        label.form-label Pagamento Anestesista:
                                        InputText(type='text' v-model='$v.model.orcamento_pagamento_anestesista.$model')
                                    .p-col-12
                                        label.form-label Jurídico:
                                        Textarea(type='text' rows="5" v-model='$v.model.orcamento_juridico.$model')

                            .p-col-12
                                Panel(header='Horários de Funcionamento' :toggleable='true'  mode="indeterminate")
                                    DataTable(:value="model.hr_funcionamento" v-if='model.hr_funcionamento.length > 0')
                                        Column(headerStyle='width: 20%' field='ar_dias_semana' header='Dia da Semana')
                                            template(#body='props')
                                                ul.ta-left
                                                    li(v-for='dia in props.data?.ar_dias_semana') {{options.dias[dia]}}
                                        Column(headerStyle='width: 10%' field='hr_inicio' header='Horário Inicial' bodyStyle='text-align: center')
                                        Column(headerStyle='width: 10%' field='hr_fim' header='Horário Final' bodyStyle='text-align: center')
                                        Column(headerStyle='width: 10%' field='' header='Ações')
                                            template(#body='props')
                                                .ta-center
                                                    Button.p-button-raised.p-button-rounded.mr-1(
                                                        type='button'
                                                        v-tooltip.top="'Visualizar'"
                                                        icon="jam jam-write"
                                                        @click='editarHorario(props.data.uid)' )
                                                    Button.p-button-danger.p-button-raised.p-button-rounded(icon="jam jam-trash" v-tooltip.top="'Remover'" type='button' @click='oepnDialogRemoverHorario(props.data)')
                                    .ta-center.mt-2
                                        Button(label='Adicionar Horário' type="button"  icon="pi pi-plus" iconPos="left" style='max-width: 50%' @click='horario_modal = true')


                            .p-col-12.mt-2.mb-2(v-if="model.id")
                                .p-grid
                                    .p-col-12.p-md-6.p-lg-3
                                        span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                    .p-col-12.p-md-6.p-lg-3
                                        span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                    .p-col-12.p-md-6.p-lg-3
                                        span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                    .p-col-12.p-md-6.p-lg-3
                                        span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                            .p-col-12.ta-right
                                Button(label='Salvar' type="submit" style='max-width:160px')
</template>

<style lang="scss">
    .estabelecimento-salvar {
        .picklist-procedimentos {
            .p-picklist-item {
                padding: 0 !important;
            }
        }
        .waitingEspecialidades-wrapper {
            text-align: center;
            padding: 80px 0;
            .p-progress-spinner {
                width: 60px;
                height: auto;
            }
        }
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
        .integracao{
            border:1px solid #eee;
            background-color:#f8f8f8;
        }
        .image-wrapper {
            font-size: 0;
            text-align: center;
            margin-top: 10px;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        .btn-remove-item{
            align-items: flex-end;
            justify-content: flex-start;
            display: flex;
            margin-top: 20px;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import Panel from 'primevue/panel'
    import FileUpload from 'primevue/fileupload'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import PickList from 'primevue/picklist'
    import Textarea from 'primevue/textarea'
    import Checkbox from 'primevue/checkbox'
    import InputNumber from 'primevue/inputnumber'
    import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
    import HorarioDialog from './HorarioDialog.vue'
    import { Estabelecimento, Protocolo, Procedimentos, Bancos, DominioValor, GrupoProcedimentos} from './../../middleware'
    import { required, minLength } from 'vuelidate/lib/validators'
    import moment from "moment"
    import {Utils} from "@/middleware"

    export default {
        components: { ProgressBar, Panel, FileUpload, InputText, Button, Password, ProgressSpinner,
                InputMask, SelectButton, Dropdown, Tooltip, PickList, Textarea, Checkbox, InputNumber, Column, DataTable, HorarioDialog  },
        directives: { tooltip: Tooltip },
        async created () {
            this.waiting = true
            Utils.getUFs().then(response => {
                this.options.ufs = [{ value: null, text: '- Selecione -', textAlt: '- Selecione -' }]
                if (response.status === 200) response.data.forEach(uf => {
                    this.options.ufs.push({ value: uf.id, text: uf.ds_sigla, textAlt: uf.nm_estado })
                })
            })
            let id = 0
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
            //this.getProcedimentos()
            this.getDominios()
            this.options.bancos.push({ value: null, text: '- Selecione -', textAlt: '- Selecione -' })
            Bancos.findAll().then(response => {
                if (response.status === 200) {
                    this.options.bancos = response.data
                }
            })
            Promise.all([
                this.getEspecialidades(),
                this.getGruposProcedimentos()
            ]).then(() => {
                if (id != 0) 
                    this.getEstabelecimento(id)
            })
            
            // this.waiting = false
        },
        data () {
            return {
                model: {
                    cd_integracao_shift: null,
                    nm_razao_social: null,
                    nm_fantasia: null,
                    nr_cnpj: null,
                    nm_responsavel_tecnico: null,
                    nm_conselho_responsavel_tecnico: null,
                    cd_uf_conselho_responsavel_tecnico: null,
                    nr_conselho_responsavel_tecnico: null,
                    nm_bairro: null,
                    nm_rua: null,
                    ds_complemento: null,
                    nr_cep: null,
                    ie_grupo: null,
                    nr_estabelecimento: null,
                    nr_latitude: null,
                    nr_longitude: null,
                    nr_telefone: null,
                    nr_whatsapp: null,
                    nr_whatsapp_cliente: null,
                    nr_ordem_listagem: null,
                    ds_email: null,
                    ds_observacao: null,
                    ds_integracao_agendamento_token: null,
                    ds_integracao_agendamento_client_secret: null,
                    ds_integracao_agendamento_cliente_id: null,
                    ds_integracao_agendamento_url: null,
                    ds_integracao_agendamento_tipo: null,
                    orcamento_pagamento_medico:null,
                    orcamento_pagamento_hospital:null,
                    orcamento_pagamento_anestesista:null,
                    orcamento_juridico:null,
                    ie_rede_credenciada:false,
                    ie_cidade_app:false,
                    aq_foto: null,
                    path_foto: '',
                    cd_cidade: null,
                    cd_estado: null,
                    nm_usuario_cri: null,
                    nm_usuario_edi: null,
                    dt_criado: null,
                    dt_atualizado: null,
                    estabelecimento_banco: [{
                        id: 0,
                        cd_banco: null,
                        nr_conta: null,
                        nr_agencia_bancaria: null,
                        nr_operacao: null,
                        ds_chave_pix: null
                    }],
                    count_bancos:0,
                    ie_notificacao_agendamentos: true,
                    ie_tipo_notificacao_agendamentos: null,
                    ie_status: true,
                    justificativa: null,
                    ie_valida_guia: true,
                    hr_funcionamento: [],
                    obj_horario: null,
                    next_uid: 0,

                },
                filters: {
                    procedimentos: ''
                },
                options: {
                    dias_da_semana: [],
                    dias: {},
                    especialidades: [],
                    procedimentos: [],
                    grupos: [],
                    grupo_procedimentos: [],
                    tipo_integracao: [
                        {text:"NENHUM", value:""},
                        {text:"SISAC", value:"SISAC"},
                        {text:"ATHENA SAUDE", value:"ATHENA"},
                        {text:"SWALLIANCE", value:"SWALLIANCE"},
                    ],
                    tipo_notificacao_whatsapp: [
                        {text:"Em lote", value:"L"},
                        {text:"Individual", value:"I"}
                    ],
                    nm_conselho_responsavel_tecnico: [],
                    // nm_conselho_responsavel_tecnico: [
                    //     { text: 'CRAS' },
                    //     { text: 'COREN' },
                    //     { text: 'CRF' },
                    //     { text: 'CRFA' },
                    //     { text: 'CREFITO' },
                    //     { text: 'CRM' },
                    //     { text: 'CRN' },
                    //     { text: 'CRO' },
                    //     { text: 'CRP' },
                    //     { text: 'CBO' },
                    //     { text: 'Outros' }
                    // ],
                    ufs: [],
                    cidades: [],
                    bancos:[]
                },
                horario_modal: false,
                waiting: true,
                waitingEspecialidades: false,
                waitingGruposProcedimentos: false,
                waitingForm: false,
                submitted: false,
            }
        },
        validations () {
            let ie_status_estabelecimento = this.model.ie_status;
            let camposObrigatorios = {required}
            let campoResponsavel = {required, minLength: minLength(2) }
            if (!ie_status_estabelecimento){// && Number.isInteger(this.model.id)){
                camposObrigatorios = {}
                campoResponsavel = {}
            }
            let v = {
                model: {
                    nm_razao_social: { required, minLength: minLength(2) },
                    nm_fantasia: { required, minLength: minLength(2) },
                    nr_cnpj: { required, minLength: minLength(2) },
                    nm_responsavel_tecnico: campoResponsavel,
                    nm_conselho_responsavel_tecnico: camposObrigatorios,
                    cd_uf_conselho_responsavel_tecnico: camposObrigatorios,
                    nr_conselho_responsavel_tecnico: camposObrigatorios,
                    nm_bairro: { required, minLength: minLength(2) },
                    nm_rua: { required, minLength: minLength(2) },
                    nr_cep: { required, minLength: minLength(2) },
                    nr_estabelecimento: { required, minLength: minLength(2) },
                    nr_telefone: { required, minLength: minLength(2) },
                    nr_whatsapp: { minLength: minLength(2) },
                    nr_whatsapp_cliente: { minLength: minLength(2) },
                    ds_email: { required, minLength: minLength(2) },
                    ds_integracao_agendamento_token: { },
                    ds_integracao_agendamento_client_secret: { },
                    ds_integracao_agendamento_cliente_id: { },
                    ds_integracao_agendamento_url: { },
                    ds_integracao_agendamento_tipo: { },
                    orcamento_juridico: { },
                    orcamento_pagamento_anestesista: { },
                    orcamento_pagamento_hospital: { },
                    orcamento_pagamento_medico: { },
                    ie_rede_credenciada: {},
                    ie_status: {},
                    justificativa: {},
                    ie_valida_guia: {},
                    ie_cidade_app: {},
                    cd_cidade: { required },
                    cd_estado: { required },
                    ie_notificacao_agendamentos: { required },
                    ie_tipo_notificacao_agendamentos: { required },
                    cd_integracao_shift: {}
                }
            }

            return v
        },
        methods: {
            handleSubmit () {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.$toast.error('Corrija os campos inválidos para prosseguir.', { duration: 3000 })
                    return 0
                }

                this.model.estabelecimento_banco.forEach((ban,i)=>{
                    let check_pix = ban.cd_banco != null && ban.ds_chave_pix != null;
                    let check_banco =  ban.cd_banco != null && ban.nr_conta != null && ban.nr_agencia_bancaria != null;
                    if(!check_banco && !check_pix) {
                        this.$delete(this.model.estabelecimento_banco, i)
                    }
                })

                if (this.model.estabelecimento_banco.length === 0) this.model.estabelecimento_banco = [{
                    id: 0,
                    cd_banco: null,
                    nr_conta: null,
                    nr_agencia_bancaria: null,
                    nr_operacao: null,
                    ds_chave_pix: null
                }]

                let dataSend = Object.assign({}, this.model)


                if (!dataSend.id) {
                    dataSend.especialidades = this.options.especialidades[1].map(e => e.cd_especialidade)
                }
                if (! dataSend.aq_foto)
                    delete dataSend.aq_foto
                // else
                //     dataSend.estabelecimento_banco = JSON.stringify(dataSend.estabelecimento_banco)

                let arrConvertOnlyNumbers = ['nr_cnpj', 'nr_telefone', 'nr_whatsapp', 'nr_whatsapp_cliente', 'nr_cep']
                arrConvertOnlyNumbers.forEach(i => { if (dataSend[i]) dataSend[i] = dataSend[i].match(/\d+/g).join('') })
                this.waitingForm = true

                Estabelecimento.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'estabelecimentos' })

                        // É realizada a busca pelos estabelecimentos para que possa ser atualizado
                        // no local storage caso possua algum estabelecimento novo ou algum tenha
                        // sido excluído
                        Estabelecimento.findAll({}).then(response => {
                            if (response.status == 200){
                                let usuario = JSON.parse(localStorage.getItem("usuario"));
                                usuario.estabelecimentos = response.data
                                localStorage.setItem('usuario', JSON.stringify(usuario))
                            }
                        })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })

                    } else if(response.status === 400) {
                        if (response.data.non_field_errors)
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                        else Object.values(response.data).forEach(errorMsg => {
                                if (typeof errorMsg == 'object')
                                    this.$toast.error(errorMsg[0], { duration: 3000 })
                                else this.$toast.error(errorMsg, { duration: 3000 })
                            })
                    }
                    this.waitingForm = false

                })
            },
            filterResult (item) {
                let flag = true
                if (this.filters.procedimentos.length > 3) {
                    flag = false
                    if (item.textAlt.indexOf(this.filters.procedimentos) == 0) flag = true
                }
                return flag
            },
            onMoveToSource (ev) {
                let removeEspecialidade = (idx) => {
                    if (!ev.items.length) return 
                    if (idx === ev.items.length) {
                        this.options.especialidades[0].sort((a, b) => a.nm_especialidade > b.nm_especialidade ? 1 : -1)
                        let _s = ev.items.length === 1 ? '' : 's'
                        if (this.model.id) this.$toast.info(`Especialidade${ _s } removida${ _s } do estabelecimento`, { duration: 3000 })
                        this.waitingEspecialidades = false
                        return 0
                    }
                    if (this.model.id) {
                        this.waitingEspecialidades = true
                        Estabelecimento.removeEspecialidade(ev.items[idx].id).then(() => {
                            removeEspecialidade(++idx)
                        })
                    } else removeEspecialidade(++idx)
                }
                removeEspecialidade(0)
            },
            onMoveToTarget (ev) {
                let addEspecialidade = (idx) => {
                    if (!ev.items.length) return
                    if (idx === ev.items.length) {
                        this.options.especialidades[1].sort((a, b) => a.nm_especialidade > b.nm_especialidade ? 1 : -1)
                        let _s = ev.items.length === 1 ? '' : 's'
                        if (this.model.id) this.$toast.success(`Especialidade${ _s } adicionada${ _s } ao estabelecimento`, { duration: 3000 })
                        this.waitingEspecialidades = false
                        return 0
                    }
                    if (this.model.id) {
                        this.waitingEspecialidades = true
                        Estabelecimento.addEspecialidade(ev.items[idx].cd_especialidade, this.model.id).then((response) => {
                            if (response.status === 201) ev.items[idx].id = response.data.id
                            addEspecialidade(++idx)
                        })
                    } else addEspecialidade(++idx)
                }
                addEspecialidade(0)
            },
            onMoveToSourceGruposProcedimentos (ev) {
                let removeGrupoProcedimento = (idx) => {
                    if (!ev.items.length) return
                    if (idx && idx === ev.items.length) {
                        this.options.grupo_procedimentos[0].sort((a, b) => a.nm_especialidade > b.nm_especialidade ? 1 : -1)
                        let _s = ev.items.length === 1 ? '' : 's'
                        if (this.model.id) this.$toast.info(`Grupo de Estabelecimento${ _s } removido${ _s } do estabelecimento`, { duration: 3000 })
                        this.waitingGruposProcedimentos = false
                        return 0
                    }
                    if (this.model.id) {
                        this.waitingGruposProcedimentos = true
                        Estabelecimento.removeGrupoProcedimento(ev.items[idx].id).then(() => {
                            removeGrupoProcedimento(++idx)
                        })
                    } else removeGrupoProcedimento(++idx)
                }
                removeGrupoProcedimento(0)
            },
            onMoveToTargetGruposProcedimentos (ev) {
                let addGrupoProcedimento = (idx) => {
                    if (!ev.items.length) return
                    if (idx === ev.items.length) {
                        this.options.grupo_procedimentos[1].sort((a, b) => a.nm_especialidade > b.nm_especialidade ? 1 : -1)
                        let _s = ev.items.length === 1 ? '' : 's'
                        if (this.model.id) this.$toast.success(`Grupo de Estabelecimento${ _s } adicionado${ _s } ao estabelecimento`, { duration: 3000 })
                        this.waitingGruposProcedimentos = false
                        return 0
                    }
                    if (this.model.id) {
                        this.waitingGruposProcedimentos = true
                        Estabelecimento.addGrupoProcedimento(ev.items[idx].cd_grupo_procedimento, this.model.id).then((response) => {
                            if (response.status === 201) ev.items[idx].id = response.data.id
                            addGrupoProcedimento(++idx)
                        })
                    } else addGrupoProcedimento(++idx)
                }
                addGrupoProcedimento(0)
            },
            getProcedimentos() {
                Procedimentos.findAll().then(response => {
                if (response.status === 200) {
                    response.data.forEach(procedimento => {
                        procedimento.textAlt = `${ procedimento.cd_procedimento } - ${ procedimento.ds_procedimento }`
                    })
                    this.options.procedimentos[0] = response.data
                    this.options.procedimentos[1] = []
                }
            })
            },
            async getEspecialidades() {
                await Protocolo.getEspecialidades().then(response => {
                    if (response.status === 200) {
                        this.options.especialidades[0] = response.data.map(especialidade => {
                            return {
                                nm_especialidade: especialidade.nm_especialidade,
                                cd_especialidade: especialidade.id }
                            })
                        this.options.especialidades[1] = []
                    }
                    this.waiting = false

                })
            },
            async getGruposProcedimentos () {
                await GrupoProcedimentos.findAll().then(response => {
                    if (response.status == 200) {
                        this.options.grupo_procedimentos[0] = response.data.map(grupo_procedimentos => {
                            return {
                                ds_descricao: grupo_procedimentos.ds_descricao,
                                cd_grupo_procedimento: grupo_procedimentos.id }
                            })
                        this.options.grupo_procedimentos[1] = []
                    }
                })
            },
            getEstabelecimento(id) {
                this.waitingEspecialidades = true
                this.waitingGruposProcedimentos = true
                Estabelecimento.find(id).then(response => {
                    if (response.status === 200) {
                        let keys = Object.keys(this.model)
                        keys.forEach(key => {
                            if (key == "hr_funcionamento") {
                                this.model[key] = response.data[key].map(
                                    item => {
                                        let auxItem = {
                                            ...item,
                                            hr_inicio: item.hr_inicio.slice(
                                                0,
                                                -3
                                            ),
                                            hr_fim: item.hr_fim.slice(
                                                0,
                                                -3
                                            ),
                                            uid: this.model.next_uid
                                        };
                                        this.model.next_uid += 1;
                                        return auxItem;
                                    }
                                );
                            } else this.model[key] = response.data[key];
                        });


                        this.model.id = id
                        this.model.path_foto = response.data.aq_foto
                        this.model.aq_foto = null
                        this.model.cd_estado = response.data.cd_estado
                        if (response.data.cd_estado) {
                            this.getCidades()
                            this.model.cd_cidade = response.data.cd_cidade
                        }
                        this.model.dt_criado_f = moment(this.model.dt_criado).format('DD/MM/YYYY HH:mm')
                        this.model.dt_atualizado_f = this.model.dt_atualizado ?
                            moment(this.model.dt_atualizado).format('DD/MM/YYYY HH:mm') : null

                        this.options.especialidades[1] = this.options.especialidades[0].filter(e1 => response.data.especialidades.map(e2 => e2.cd_especialidade).includes(e1.cd_especialidade))
                        this.options.especialidades[1].forEach(especialidade => { especialidade.id = response.data.especialidades.find(e => e.cd_especialidade == especialidade.cd_especialidade).id })
                        this.options.especialidades[0] = this.options.especialidades[0].filter(e1 => !response.data.especialidades.map(e2 => e2.cd_especialidade).includes(e1.cd_especialidade))

                        this.options.grupo_procedimentos[1] = this.options.grupo_procedimentos[0].filter(e1 => response.data.grupo_procedimentos.map(e2 => e2.cd_grupo_procedimento).includes(e1.cd_grupo_procedimento))
                        this.options.grupo_procedimentos[1].forEach(grupo_procedimentos => { grupo_procedimentos.id = response.data.grupo_procedimentos.find(e => e.cd_grupo_procedimento == grupo_procedimentos.cd_grupo_procedimento).id })
                        this.options.grupo_procedimentos[0] = this.options.grupo_procedimentos[0].filter(e1 => !response.data.grupo_procedimentos.map(e2 => e2.cd_grupo_procedimento).includes(e1.cd_grupo_procedimento))

                        this.waitingEspecialidades = false
                        this.waitingGruposProcedimentos = false

                        if (this.model.estabelecimento_banco.length === 0) this.model.estabelecimento_banco = [{
                            id: 0,
                            cd_banco: null,
                            nr_conta: null,
                            nr_agencia_bancaria: null,
                            nr_operacao: null,
                            ds_chave_pix: null
                        }]
                        else {
                            this.model.estabelecimento_banco = this.model.estabelecimento_banco.map((ban, i) => {
                                ban.id = i;
                                if(ban.cd_banco) ban.cd_banco = ban.cd_banco.id;
                                return ban
                            })
                            this.model.count_bancos = this.model.estabelecimento_banco.length;
                        }
                    }
                })
            },

            getCidades() {
                this.model.cd_cidade = null
                Utils.getMunicipiosEstadoId(this.model.cd_estado).then(response => {
                    this.options.cidades = []
                    if (response.status === 200) {
                        this.options.cidades = response.data.map(cidade => {
                            return { value: cidade.id, text: cidade.nm_cidade }
                        })
                        this.options.cidades.unshift({ value: null, text: '- Selecione -' })
                    }
                })
            },
			toBase64 (file) {
				return new Promise((resolve, reject) => {
					let reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => resolve(reader.result);
					reader.onerror = error => reject(error);
				})
			},
			async beforeSendImage(ev) {
                ev.xhr.open('GET', '/')

                if (! ev.formData.get('aq_foto').type.includes("image/")) {
                    this.$toast.error("Tipo do arquivo é inválido! Precisa ser imagem.")
                    return
                }
                // Max size 2mb
                if (! ev.formData.get('aq_foto').size > 2097152) {
                    this.$toast.error("Tamanho do arquivo é inválido! Tamanho máximo: 2mb.")
                    return
                }

                this.model.aq_foto = await this.toBase64(ev.formData.get('aq_foto')).then( response => response)
				this.model.path_foto = URL.createObjectURL(ev.formData.get('aq_foto'))
				// this.toBase64(this.model.aq_foto).then(response => {
				// 	console.log(response)
				// })

            },
            getDominios() {
                DominioValor.findAll({ds_mnemonico: ['GRUPO_ESTABELECIMENTOS', 'DIAS_SEMANA', 'CONSELHO_MEDICO']}).then(response => {
                if (response.status == 200){
                    response.data['GRUPO_ESTABELECIMENTOS'].valores.forEach((un) => {
                        this.options.grupos.push({ value: un.ie_valor, label: un.ds_valor})
                    });
    
                    this.options.dias_da_semana = response.data['DIAS_SEMANA']?.['valores'];
                        this.options.dias = response.data['DIAS_SEMANA']?.['valores'].reduce((acc, item) => {
                            acc[item.ie_valor] = item.ds_valor;
                            return acc;
                        }, {});
                    }
                    this.options.nm_conselho_responsavel_tecnico = response.data['CONSELHO_MEDICO'].valores.map(item => ({
                        label: item.ds_valor,
                        value: item.ie_valor
                    }))
                })
            },
            addBanco(list){
                let check_pix = list.cd_banco != null && list.ds_chave_pix != null;
                let check_banco =  list.cd_banco != null && list.nr_conta != null && list.nr_agencia_bancaria != null;
                if(check_pix || check_banco) {
                    this.model.estabelecimento_banco.push({
                        id: ++this.model.count_bancos,
                        cd_banco: null,
                        nr_conta: null,
                        nr_agencia_bancaria: null,
                        nr_operacao: null,
                        ds_chave_pix: null
                    })
                }
            },

            removeBanco(index){
                if(index > 0) {
                    this.$delete(this.model.estabelecimento_banco,index)
                } else {
                    if(this.model.estabelecimento_banco.length > 1)
                        this.$delete(this.model.estabelecimento_banco,index)
                    else {
                        this.model.estabelecimento_banco = [{
                            id: 0,
                            cd_banco: null,
                            nr_conta: null,
                            nr_agencia_bancaria: null,
                            nr_operacao: null,
                            ds_chave_pix: null
                        }]
                    }
                }
            },
            onCloseHorarioDialog() {
                this.horario_modal = false;
            },
            handleHorasDialog() {
                this.horario_modal = true;
            },
            excluirHorario(uid) {
                const auxIndex = this.model.hr_funcionamento.findIndex(
                    item => item.uid == uid
                );
                this.model.hr_funcionamento.splice(auxIndex, 1);
            },
            editarHorario(uid) {
                const auxIndex = this.model.hr_funcionamento.findIndex(
                    item => item.uid == uid
                );
                this.model.obj_horario = {
                    ar_dias_semana: this.model.hr_funcionamento[auxIndex]
                        .ar_dias_semana,
                    id: this.model.hr_funcionamento[auxIndex].id,
                    hr_inicio: this.model.hr_funcionamento[auxIndex].hr_inicio,
                    hr_fim: this.model.hr_funcionamento[auxIndex].hr_fim,
                    uid: uid
                };
                this.horario_modal = true;
            },

        }
    }
</script>
